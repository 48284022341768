//["Maor" 0, "Yali"1, "default"2, "Nagito"3, "Monokuma"4, "Papyrus"5, "SEXMAN"6]

const endings = [
    //ending 0
    {
        title: "amogus Ending",
        description: 'You both said "amogus" repeatedly until the end of time and the conversation has gone nowhere.',
        suggestion: "Try to be funnier next time",
        unlock: 1
    },
    //ending 1
    {
        title: "Mean Ending",
        description: 'You stopped the conversation before it even started.',
        suggestion: "Dumbass",
    },
    //ending 2
    {
        title: "Bully Ending",
        description: 'You bullied them so they blocked you.',
        suggestion: "Try to be nicer next time",
        unlock: 4
    },
    //ending 3
    {
        title: "Bad Ending",
        description: 'You were mean and made them sad so they blocked you.',
        suggestion: "Try to be nicer next time"
    },
    //ending 4
    {
        title: "Good Ending",
        description: 'You got married!!!',
        suggestion: "Good job",
        unlock: 0
    },
    //ending 5
    {
        title: "Nagito Komaeda Ending",
        description: "You proposed to Nagito komaeda instead and he accepted!",
        suggestion: "Good choice",
        unlock: 3
    },

    //ending 6
    {
        title: "Boring Ending",
        description: "You're boring so nothing happened.",
        suggestion: "Be less boring",
        unlock: 2
    },
    //ending 7
    {
        title: "What? Ending",
        description: "Why did you say that",
        suggestion: "",
        unlock: 5
    },
    //ending 8
    {
        title: "True Ending",
        description: "Got real",
        suggestion: ""
    },
    //ending 9
    {
        title: "Monkey Ending",
        description: "secret monkey?!!???!",
        suggestion: "get some monkies",
        unlock: 6
    },
    //ending 10
    {
        title: "Sans Dog Ending",
        description: "sans dog is too op",
        suggestion: "be a decent human being and play fair",
        unlock: 7
    },
    //ending 11
    {
        title: "Cheater Ending",
        description: "skill issue",
        suggestion: ""
    },
    //ending 12
    {
        title: "Spamton Neo Ending",
        description: "OrangeNOrange is proud of you.",
        suggestion: ""
    },
    //ending 13
    {
        title: "OrangeNOrange and Orange Ending",
        description: "This is getting out of hand, now there are two of them!"
    },
    //ending 14
    {
        title: "Dead Ending",
        description: "Papyrus has thrown you to #### and you have successfully died.",
        suggestion: ""
    }
]

export default endings